<template>
    <AppContainer>
        <Queue/>
    </AppContainer>
</template>

<script>
  import AppContainer from '@/components/containers/UserDashboardContainer.vue'
  import Queue from '@/components/userDashboard/queue/Queue.vue'

  export default {
    components: {
      AppContainer,
      Queue
    }
  }
</script>