<template>
    <div class="card mb-0 border">
        <div class="card-header">
            <h3 class="card-title text-dark">Queues</h3>
        </div>
        <div class="card-body p-1">
            <!-- Appointments-table-area  -->
            <div class="settings-tab my-3">
                <ul class="nav nav-tabs nav-justified tabs-menu" role="tablist">
                    <li class="nav-item">
                        <router-link class="nav-link" :class="$route.params.type == 'today' ? 'active':''" :to="{ name: 'userQueue',params:{type:'today'} }">Today</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :class="$route.params.type == 'past' ? 'active':''" :to="{ name: 'userQueue',params:{type:'past'} }">Past</router-link>
                    </li>
                </ul>
                <div class="leads-table-area addcustomer-table addcampaigm-table" v-show="$route.params.type == 'past'">
                    <table id="queue_table" class="display" style="width: 100%;">
                        <thead>
                            <tr>
                                <th class="Adds">ID</th>
                                <!-- <th>Created Date</th> -->
                                <th>Customer</th>
                                <th>Provider</th>
                                <th>Price</th>
                                <th>Duration</th>
                                <th style="width:70px">Status</th>
                                <th>Schedule</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>



                <!-- Queue-area -->
                <div class="desking-area" v-if="$route.params.type == 'today' || $route.params.type == ''">
                    <!-- desking-box -->
                    <div class="desk-mid-box desk-setting-mid">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation" v-for="(item,index) in queues" :key="index">
                                <a class="nav-link" :class="index == 0 ? 'active':''" :id="'queuePerson-tab'+index" data-bs-toggle="tab" :href="'#queuePerson'+index" role="tab" :aria-controls="'queuePerson'+index">
                                    <img v-if="item.provider.picture" class="img-fluid mr-2" style="width:40px;vertical-align: middle;" :src="item.provider.picture" >
                                    <img v-else class="img-fluid mr-2" style="width:40px;vertical-align: middle;" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" >
                                    {{item.provider.name}}
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="Taxes-tab" data-bs-toggle="tab" href="#Taxes" role="tab" aria-controls="Taxes" aria-selected="false"><img class="img-fluid mr-2" style="width:40px;vertical-align: middle;" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" >Muhammad Usman</a>
                            </li> -->
                        </ul>
                        <div class="tab-content">
                            <div v-for="(item,index) in queues" :key="index" class="tab-pane fade" :class="index == 0 ? 'show active':''" :id="'queuePerson'+index" role="tabpanel" :aria-labelledby="'queuePerson-tab'+index">
                                <div class="queue-content-area">
                                    <div class="row" v-if="item.activeQueues.length > 0">
                                        <div class="col-12">
                                            <div class="detais-title">
                                                <h3>Active Queue</h3>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-lg-8">
                                            <div class="row Taxes-content">
                                                <div class="col-sm-4 pt-5">
                                                    <!-- <button class="rem-btn success-btn float-right mt-5" v-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'start','queue')"><i class="fa fa-check" style="font-size: 16px;"></i>Start</button>

                                                    <button class="rem-btn warning-btn mt-5 float-right" v-if="item.activeQueues[0].booking_status == 1">
                                                        <div class="spinner-grow text-secondary" style="width: 14px;height: 14px;" role="status">
                                                            <span class="visually-hidden">Processing...</span>
                                                        </div>Processing..
                                                    </button> -->
                                                </div>
                                                <div class="col-sm-4 text-center">
                                                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" style="max-height: 120px;">
                                                    <h4>{{item.activeQueues[0].user_firstname}} {{item.activeQueues[0].user_lastname}}</h4>
                                                </div>
                                                <div class="col-sm-4 pt-5">
                                                    <!-- <button class="add-btn mt-5 danger-btn float-left" v-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'cancel','queue')"><i class="fa fa-times" style="font-size: 16px;"></i>Cancel</button>
                                                    <button class="add-btn mt-5 ml-1 float-left" v-if="item.activeQueues[0].booking_status == 0" v-on:click="updateBookingStatus(item.activeQueues[0].id,'no-show','queue')"><i class="fa fa-times" style="font-size: 16px;"></i>No Show</button>
                                                    <button class="rem-btn success-btn float-left mt-5" v-if="item.activeQueues[0].booking_status == 1" v-on:click="updateBookingStatus(item.activeQueues[0].id,'complete','queue')"><i class="fa fa-check" style="font-size: 16px;"></i>Complete</button> -->
                                                </div>
                                                <div class="col-12 pt-3">
                                                    <p>Overall progress:</p>
                                                    <div class="progress">
                                                        <div class="progress-bar bg-success" v-bind:style="{ width: item.activeQueues[0].overAllProgress > 100 ? '100%':item.activeQueues[0].overAllProgress+'%'}">
                                                            <span v-if="item.activeQueues[0].overAllProgress > 100">100%</span>
                                                            <span v-else>{{item.activeQueues[0].overAllProgress}}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <div class="fw-bolder text-muted" v-if="item.activeQueues[0].overAllProgress < 40">Starting…</div>
                                                    <div class="fw-bolder text-muted" v-else-if="item.activeQueues[0].overAllProgress < 70">Awesome…</div>
                                                    <div class="fw-bolder text-muted" v-else>Rounding up…😊</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-lg-4">
                                            <div class="detais-title">
                                                <h3>Services</h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-12" v-for="serv in item.activeQueues[0].boooking_services" :key="serv.id">
                                                    <div class="title d-inline-block w-50">{{serv.title}}</div>
                                                    <div class="value d-inline-block text-right w-50">${{serv.cost}}
                                                        <span class="text-muted small">({{serv.duration}} min)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="item.activeQueues.length > 1">
                                        <div class="col-12 mt-4">
                                            <div class="detais-title">
                                                <h3>Next in Queue</h3>
                                            </div>
                                            <div class="todayqueuewraper">
                                                <div class="queue-arousel" v-if="item.activeQueues.length > 0">
                                                    <Carousel :items-to-show="3" :itemsToScroll="3" :breakpoints="breakpoints" class="queues-arousel">
                                                        <Slide v-for="(queue,index) in item.activeQueues.slice(1)" :key="index">
                                                            <div class="item">
                                                                <div class="pad15">
                                                                    <div class="bk-image">
                                                                        <img src="@/assets/images/icons/user-icon.jpg" />
                                                                    </div>
                                                                    <div class="queuetime" style="background:url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/queuetime.png') no-repeat top"> {{
                                                                        changeTimeFormate(queue.booking_date+' '+queue.booking_start_time,12)}}
                                                                    </div>

                                                                    <div class="booking-time">O{{index+1}}</div>
                                                                    <div class="booking-id"> {{queue.user_firstname}} </div>
                                                                </div>
                                                            </div>
                                                        </Slide>
                                                        <template #addons>
                                                        <Navigation />
                                                        </template>
                                                    </Carousel>


                                                    <!-- <Carousel :items-to-show="3" :itemsToScroll="3" :breakpoints="breakpoints" >
                                                        <Slide v-for="(queue,index) in item.activeQueues.slice(1)" :key="index">
                                                            <div class="queuelistingwrp">
                                                                <div class="queuetime" style="background:url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/queuetime.png') no-repeat top"> {{
                                                                    changeTimeFormate(queue.booking_date+' '+queue.booking_start_time,12)}}</div>
                                                                <div class="queueimglist" style="background-image:url(https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg);">  &nbsp; </div>
                                                                <h3>{{queue.user_firstname}}</h3>
                                                            </div>
                                                        </Slide>
                                                        <template #addons>
                                                        <Navigation />
                                                        </template>
                                                    </Carousel> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mt-2" v-if="item.activeQueues.length == 0">
                                        <h6>No one is in queue.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="leads-table-area py-4 no-queue" v-if="queues.length == 0">
                            <div class="text-center mt-2" >
                                <h6>You don't have any queue.</h6>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Queue-area -->

            </div>
            <!-- Appointments-table-area  -->
        </div>
    </div>

</template>

<script>
    import axios from "axios"
    import $ from "jquery"
    import { useToast } from "vue-toastification"
    import { Carousel, Navigation, Slide } from 'vue3-carousel'
    import 'vue3-carousel/dist/carousel.css'

    import "datatables.net"
    import "datatables.net-responsive"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-responsive-dt/css/responsive.dataTables.min.css"
    import moment from "moment"

    //import StarRating from 'vue-star-rating'

    export default {
        name: "Queue",
        setup () {
            const toast = useToast();
            return {toast}
        },
        components: {
            Carousel,
            Slide,
            Navigation,
            //StarRating
        },
        data() {
            return {
                review_:{
                    businessReview: '',
                    userReview: '',
                    services: 0

                },
                bookingType:1,
                queues:[],
                apptCounters:{
                    future_appointments:0,
                    past_appointments:0,
                    past_queues:0,
                    today_appointments:0,
                    today_queues:0
                },
                breakpoints: {
                    575: {
                        itemsToShow: 4,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    },
                    // 700px and up
                    992: {
                        itemsToShow: 5,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    },
                    // 1024 and up
                    1200: {
                        itemsToShow: 6,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    }
                },
            };
        },
        created() {
            //this.getBookingCounter()
            this.getQueus();
        },
        methods: {
            getQueus(){
                let thiss = this;
                axios.get('client_queues').then(function (response) {
                    console.log(response.data.data)
                    if (response.data.status == 'success') {
                        thiss.queues = response.data.data;
                        console.log(thiss.queues[0]);
                        if(thiss.queues.length>0){
                            thiss.queues.forEach((item) => {
                                var status= item.activeQueues[0].booking_status;
                                if( status == 1){
                                    let startTime = new Date(item.activeQueues[0].booking_start_date);
                                    let totalTime =item.activeQueues[0].booking_duration;
                                    setInterval(function () {
                                        item.activeQueues[0].overAllProgress = thiss.progressBar(startTime,totalTime);
                                        console.log(thiss.progressBar(startTime,totalTime));
                                    }, 1000);

                                }
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }else if (error.response.status == 401) {
                            thiss.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            progressBar(startTime,percentage) {
                let currentTime = new Date();
                var timeDiff = (currentTime.getTime() - startTime.getTime()) / 1000;
                var mint = timeDiff / 60;
                var per = (mint / percentage) * 100;
                if(per > 100){
                    per = 100;
                }
                return Math.abs(Math.round(per));
            },
            updateBookingStatus(id,status,type='booking'){
                let thiss = this
                let msg = "";
                if (status == 'no-show') {
                    msg = "You don't meet this customer."
                }else if (status == 'start') {
                    msg = "You want to start this booking!"
                }else if(status == 'cancel'){
                    msg = "You want to cancel this booking!"
                }else if(status == 'complete'){
                    msg = "This appointment is successfully completed!"
                }


                this.$swal({
                    title: 'Are you sure?',
                    text: msg,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: false,
                    customClass: {
                        confirmButton: 'ond-btn-p me-3',
                        cancelButton: 'ond-btn-w btn-raq'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('update_booking_status',{booking_id:id,status:status}).then(function (response) {
                            if (response.data.status == 'success') {
                                if (type == 'queue') {
                                    thiss.getQueus();
                                }else{
                                    $("#queue_table").dataTable().fnDraw();
                                    thiss.toast.success(response.data.message);
                                }
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == 422) {
                                    thiss.toast.error(error.response.data.message);
                                }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                    thiss.$storage.removeStorageSync("user")
                                    thiss.$storage.removeStorageSync("token")
                                    thiss.$storage.removeStorageSync("business")
                                    thiss.$router.push({ name: 'Home'})
                                }else if (error.response.status == 401) {
                                    thiss.toast.error(error.response.data.message,{position: "top-right"});
                                }
                            }
                        });
                    }
                })

            },
            updateData(value) {
                if(value != 'queue'){
                    $("#queue_table").dataTable().fnDraw();
                }
                this.$router.push({ name: 'userQueue' })
            },
            changeTimeFormate(time,frmt){
                if (frmt == 12) {
                    return moment(new Date(time)).format("hh:mm a");
                }else if (frmt == 24) {
                    return moment(new Date(time)).format("HH:mm");
                }else{
                    return moment(new Date(time)).format(frmt);
                }
            },
            // getBookingCounter(){
            //     let thiss = this;
            //     axios.get('bookings_counter/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
            //         console.log(response.data.data)
            //         if (response.data.status == 'success') {
            //             thiss.apptCounters = response.data.data;
            //         }
            //     })
            //     .catch(error => {
            //         if (error.response) {
            //             if (error.response.status == 422) {
            //                 thiss.toast.error(error.response.data.message);
            //             }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
            //                 thiss.$storage.removeStorageSync("user")
            //                 thiss.$storage.removeStorageSync("token")
            //                 thiss.$storage.removeStorageSync("business")
            //                 thiss.$router.push({ name: 'Home'})
            //             }else if (error.response.status == 401) {
            //                 thiss.toast.error(error.response.data.message,{position: "top-right"});
            //             }
            //         }
            //     });
            // },
            review(){

                    console.log(this.review_)
                    let thiss = this
                    axios.post('give_review',this.review_).then(function (response) {
                        if (response.data.status == 'success') {
                            $("#exampleModal").modal("hide");
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });

            }
        },
        mounted() {
            let thiss = this;

            $("#queue_table").dataTable({
                //order: [[7, "desc"]],
                responsive: true,
                fixedHeader: true,
                processing: true,
                serverSide: true,
                ajax: {
                    url: axios.defaults.baseURL + "dt_appointments/client",
                    type: "POST",
                    data: function (d) {
                        d.duration = 'past';
                        d.booking_type = 1;
                    },
                    headers: {
                        Authorization: axios.defaults.headers.common['Authorization'],
                    },
                },
                drawCallback: function () {
                    //alert( 'Table redrawn '+new Date() );
                },
                columnDefs: [
                    {
                        targets: [3, 4, 5],
                        orderable: false,
                    },
                ],
                //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
            });

            // $(".past_queues_table").dataTable({
            //     responsive: true,
            //     fixedHeader: true,
            //     processing: false,
            //     serverSide: false,
            // });

            $("tbody").on("click", ".change-status", function (e) {
                e.preventDefault();
                let booking_id = $(this).data("id");
                let status = $(this).data("status");
                thiss.updateBookingStatus(booking_id,status)
            });

            $("tbody").on("click", ".give-review", function (e) {
                e.preventDefault();
                let booking_id = $(this).data("id");
                let emp = $(this).data("emp_name");
                let businessName = $(this).data("business_name");
                //let businessImg = $(this).data("business_img");
                thiss.review_.order_id = booking_id;
                $("#emp").html(emp);
                $("#businessName").html(businessName);
                //$("#businessImg").attr("src",businessImg);
            });
        }
    }
</script>
<style scoped>
    /* Queue */
    .queue-content-area{
        padding: 20px;
    }

    .counters.active{
        background: #3151A1;
    }
    .counters.active .counter-num p, .counters.active .counter-num h4{
        color: #fff
    }
    .dataTables_wrapper{
        overflow: inherit !important
    }
</style>
<style type="text/css">
    .todayqueuewraper {
        padding: 40px 5px;
        width: 100%;
    }
    /* .queuelistingwrp {
        background: #7f5079;
        padding: 10px;
        width: 132px;
        margin-left: 9px;
        float: left;
        margin-bottom: 25px;
        color: #fff;
    } */
    /* .queuelistingwrp .queuetime {
        padding: 2px 0 4px 0;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-top: -20px;
    } */
    /* .queuelistingwrp .queueimglist {
        background-repeat: no-repeat;
        background-size: 100%;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-top: 20px;
        margin: 0 auto;
        margin-top: 15px;
        border: 4px solid #553c57 !important;
    }
    .queuelistingwrp h3 {
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-top: 10px
    } */
    .carousel__viewport{
        overflow: inherit;
    }
    .queue-arousel .queuetime {
        padding: 2px 0 4px 0;
        text-align: center;
        color: #fff;
        font-size: 12px;
        /* margin-top: -20px; */
    }
</style>

<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>